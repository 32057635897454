<template>
  <CButton :size="size" :disabled="disabled" bg="buttonSecondary" color="breadcrumbNotActive2" :_hover="{ bg: 'buttonSecondary' }">{{ label }}</CButton>
</template>

<script>
import { CButton } from "@chakra-ui/vue"

export default {
  name: "ButtonSecondary2",
  components: {
    CButton
  },
  props: { 
    label: {
      type: String,
      default: "Button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "md"
    }
  }
}

</script>