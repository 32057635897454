<template>
  <!-- <CBox bg="bgMain" height="full">
    <CBox ml="60px" mr="60px" pt="20px" pl="10px" pr="10px" pb="5px"> -->
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
  <CBox my="20px">
      <CGrid templateColumns="7fr 3fr" :gap="6">
        <CBox w="100%" h="min-content" bg="white" border="1px" borderColor="borderBox">
          <!-- <CBox d="flex" mx="32px" my="15px">
            <BreadcrumbUpload />
          </CBox> -->
          <!-- <CDivider borderColor="borderBox" border="1.5px" /> -->
          <!-- <CBox mx="32px" my="20px"> -->
            <CBox v-if="!currentUser.emp_id">
              <CBox h="20px" />
              <Loading />
            </CBox>
            <CSimpleGrid v-if="currentUser.emp_id" mx="32px" my="20px" :columns="2" mr="10px" maxH="calc(100vh - 270px)" overflowY="scroll">
              <CBox borderRightWidth="3px" borderColor="gray.200">
                <CText fontWeight="bold" color="fontMain" my="10px">Upload Foto Web CV</CText>
                <input type="file" ref="input" @input="setImage" style="display: none" />
                <img :src="currentUser.emp_photo_web_cv" style="display: none" @load="isLoadImg = true" />
                <CImage v-if="currentUser.emp_photo_web_cv && !imgSrc && isLoadImg" :src="currentUser.emp_photo_web_cv" my="15px" w="247px" mx="auto" />
                <CBox v-if="currentUser.emp_photo_web_cv && !imgSrc && !isLoadImg" w="200px" h="200px" mx="auto">
                  <Loading style="margin-top: 100px" />
                </CBox>
                <CImage v-if="!imgSrc && !currentUser.emp_photo_web_cv" class="pointer" :src="require('@/assets/img/unggah-foto.png')" @click.native="showFileChooser" my="15px" w="247px" />
                <CImage v-if="cropImg" :src="cropImg" my="15px" w="247px" />
                <VueCropper
                  v-if="imgSrc && !cropImg"
                  ref="cropper"
                  :aspect-ratio="8 / 10"
                  :src="imgSrc"
                  preview=".preview"
                  style="height: 309px; margin-right: 30px"
                />
                <CBox d="flex" mt="10px" v-if="imgSrc || currentUser.emp_photo_web_cv">
                  <ButtonSecondary2 @click.native="deleteImage()" label="Hapus Gambar Ini" size="sm" />
                  <ButtonBlue @click.native="changeImage()" label="Gunakan Gambar Lain" size="sm" style="margin-left: 10px" />
                </CBox>
                <CBox mt="20px">
                  <CText fontSize="12px" color="fontMain" fontWeight="bold">Ketentuan Foto</CText>
                  <CBox>
                    <CBox d="table-cell" direction="row" verticalAlign="middle">
                      <CBox d="table-cell" verticalAlign="middle" h="inherit">
                        <CImage :src="require('@/assets/icon/bullet.png')" w="7px" h="7px" />
                      </CBox>
                      <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
                        <CText color="breadcrumbActive2" fontSize="12px">Gunakan foto dengan resolusi minimal 300px x 300px</CText>
                      </CBox>
                    </CBox>
                  </CBox>
                  <CBox>
                    <CBox d="table-cell" direction="row" verticalAlign="middle">
                      <CBox d="table-cell" verticalAlign="middle" h="inherit">
                        <CImage :src="require('@/assets/icon/bullet.png')" w="7px" h="7px" />
                      </CBox>
                      <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
                        <CText color="breadcrumbActive2" fontSize="12px">Aspek rasio foto adalah 8:10</CText>
                      </CBox>
                    </CBox>
                  </CBox>
                  <CBox>
                    <CBox d="table-cell" direction="row" verticalAlign="middle">
                      <CBox d="table-cell" verticalAlign="middle" h="inherit">
                        <CImage :src="require('@/assets/icon/bullet.png')" w="7px" h="7px" />
                      </CBox>
                      <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
                        <CText color="breadcrumbActive2" fontSize="12px">Latar belakang yang digunakan adalah transparan</CText>
                      </CBox>
                    </CBox>
                  </CBox>
                </CBox>
              </CBox>
              <CBox>
                <CBox ml="20px" mr="10px">
                  <CText fontWeight="bold" color="fontMain" my="10px">Preview Foto Web CV</CText>
                  <CImage v-if="!imgSrc" :src="require('@/assets/img/empty-photo.png')" />
                  <CBox v-else align="center">
                    <div v-if="!cropImg" class="preview" />
                    <CImage v-if="cropImg && photoUsed == 'original'" :src="cropImg" />
                    <CImage v-if="removePhoto && photoUsed == 'remove'" :src="removePhoto" />
                    <ButtonBlue :label="labelRemoveButton" mt="10px" :disabled="disabledRemoveButton" @click.native="processPhoto()" />
                  </CBox>
                </CBox>
              </CBox>
            </CSimpleGrid>
            <CDivider borderColor="borderBox" border="1.5px" v-if="currentUser.emp_id" />
            <CBox align="right" m="20px" v-if="currentUser.emp_id">
              <ButtonBlue :disabled="disabledButton" label="Lanjutkan" @click.native="onSubmit()" />
            </CBox>
          <!-- </CBox> -->
        </CBox>
        <CBox>
          <CBox mx="10px" w="100%" bg="white" border="1px" borderColor="borderBox" style="position: relative;">
            <CBox class="coba" borderLeft="1px" borderBottom="1px" borderColor="borderBox" style="position: absolute; top: 20px;" h="10px" />
            <CBox my="20px" mx="15px">
              <CText fontWeight="bold" color="fontInformation">Foto Web CV</CText>
              <CText color="fontInformation" fontSize="14px">
                Unggah foto profilemu yang akan tampil pada Web CV kamu. Kamu dapat mengunggah foto dengan background transparan agar terlihat lebih menarik.
              </CText>
              <CImage :src="require('@/assets/img/upload-photo-ilustration.png')" />
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
      <!-- <WizardFooter @onSubmit="onSubmit()" :disabledButton="disabledButton" /> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER, UPDATE_USER_EMPLOYEE } from "@/store/auth.module"
import { POST_BG_PHOTO } from "@/store/photo.module"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
import ButtonSecondary2 from "@/components/button/ButtonSecondary2.vue"
// import BreadcrumbUpload from "@/components/breadcrumb/BreadcrumbUpload.vue"
import Swal from "sweetalert2";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Loading from "@/components/Loading.vue";
// import WizardFooter from "@/components/footer/WizardFooter.vue";

export default {
  metaInfo: {
    title: "Unggah Foto",
    titleTemplate: "%s - Gethired.id"
  },
  components: {
    ButtonBlue,
    ButtonSecondary2,
    // BreadcrumbUpload,
    VueCropper,
    Loading,
    // WizardFooter
  },
  data() {
    return {
      imgSrc: "",
      cropImg: "",
      photoUsed: "original",
      removePhoto: "",
      removePhotoPreview: "",
      labelRemoveButton: "Hapus Latar Belakang",
      disabledRemoveButton: false,
      disabledButton: false,
      currentUser: {},
      isLoadImg: false,
      isDeletePhoto: false,
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    // currentUser() {
    //   return this.$store.getters.currentUser;
    // }
  },
  methods: {
    async getData() {
      // setTimeout(async () => {
      try {
        await this.$store.dispatch(GET_USER)
        const user = this.$store.getters.currentUser;
        this.currentUser = user;
      } catch (err) {
        let errData = err.message;
        if (err.response && err.response.data && err.response.data.message)
          errData = err.response.data.message;
        Swal.fire({
          title: "Oops . .",
          text: errData,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      }
      // }, 100);
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    setImage(e) {
      this.cropImg = "";
      this.removePhoto = "";
      this.labelRemoveButton = "Hapus Latar Belakang";

      const file = e.target.files[0];

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    deleteImage() {
      this.imgSrc = "";
      this.cropImg = "";
      this.removePhoto = "";
      this.isDeletePhoto = true;
      this.$refs.input.value = null;
      this.labelRemoveButton = "Hapus Latar Belakang";
      if (this.currentUser.emp_photo_web_cv) this.currentUser.emp_photo_web_cv = ""; 
    },
    changeImage() {
      this.$refs.input.click();
    },
    async blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    processPhoto() {
      if (this.photoUsed == 'original' && this.removePhoto) {
        this.labelRemoveButton = "Gunakan Foto Asli";
        this.photoUsed = "remove";
        return;
      }
      if (this.photoUsed == 'remove') {
        this.labelRemoveButton = "Hapus Latar Belakang";
        this.photoUsed = "original";
        return;
      }
      this.disabledRemoveButton = true
      this.disabledButton = true
      this.$refs.cropper.getCroppedCanvas({
        maxWidth: 1024,
        maxHeight: 1024,
      }).toBlob(async (blob) => {
        this.$store.dispatch(POST_BG_PHOTO, {data: blob})
        .then(async (result) => {
          this.labelRemoveButton = "Gunakan Foto Asli"
          this.disabledRemoveButton = false;
          this.disabledButton = false;
          this.photoUsed = "remove";
          const base64 = Buffer.from(result.data, 'binary').toString('base64')
          this.removePhoto = "data:image/png;base64,"+base64;
          this.cropImg = await this.blobToBase64(blob);
        })
        .catch((err) => {
          this.disabledRemoveButton = false;
          this.disabledButton = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
      });
    },
    onSubmit() {
      this.disabledButton = true;
      if (this.imgSrc && !this.cropImg) {
        this.cropImg = this.$refs.cropper.getCroppedCanvas({
          maxWidth: 1024,
          maxHeight: 1024,
        }).toDataURL();
      }
      let photoData = this.photoUsed == "original" ? this.cropImg : this.removePhoto;
      if (!photoData && !this.isDeletePhoto) photoData = undefined;

      this.$store
        .dispatch(UPDATE_USER_EMPLOYEE, {
          id: this.currentUser.emp_id,
          data: {
            emp_email: this.currentUser.emp_email,
            emp_photo_web_cv: photoData,
          }
        })
        .then(() => {
          this.disabledButton = false;
          this.$router.push({ name: "Video CV" })
        })
        .catch((err) => {
          this.disabledButton = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
    },
  }
}
</script>

<style scoped>
.coba {
  content: "";
  left: calc(-0.5rem);
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  display: block;
  width: 1rem;
  height: 1rem;
}
.pointer {
  cursor: pointer;
}
.preview {
  width: 100%;
  height: calc(100vw * 10/8);
  overflow: hidden;
}
</style>
